@media (max-width: 900px) {
  .footermoilfirst {
    display: grid !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center;
  }
}

@media (min-width: 901px) {
  .footermoilsecond {
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
  }
}
