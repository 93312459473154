@media (max-width: 900px) {
  .textMobil {
    font-size: 35px !important;
  }
}

@media (max-width: 900px) {
  .subtitle {
    font-size: 18px !important;
  }
}

.bg-color-custom {
  background-color: #263951 !important ;
}

.text{
  color:#f8f9fa !important ;
}